import React from 'react';
import Section from '../components/Section';
import DefaultLayout from '../layouts/DefaultLayout';
import { Body, DesktopOnly, H3, H4, LenLink, MobileOnly, Reference, Ul } from '../util/typography';
import Subsection from '../components/Subsection';
import TextIcon1Column from '../components/TextIcon1Column';
import personIcon from '../assets/content-icons/icon-person-green.png';
import personIconMulberry from '../assets/content-icons/icon-person-mulberry.png';
import ivIconGreen from '../assets/content-icons/icon-iv-green.png';
import pillIconMulberry from '../assets/content-icons/icon-pill-mulberry.png';
import clipboardIconGreen from '../assets/content-icons/icon-clipboard-green.png'
import clipboardIconMulberry from '../assets/content-icons/icon-clipboard-mulberry.png'
import chartDesktopImg from '../assets/content-icons/lenvima-chart-desktop.png';
import chartMobileImg from '../assets/content-icons/lenvima-chart-mobile.png';
import Accordion from '../components/Accordion';
import styled from 'styled-components';
import Chart from '../components/Chart';
import * as colors from '../util/colors';
import { respondTo } from '../util/breakpoints';
import Table from '../components/Table';
import RelatedContent from '../components/RelatedContent';
import { jobCodes } from '../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) | Safety`,
    keywords: `keytruda and lenvima safety`,
    description: `Health care professionals can review additional safety information for KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/safety/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/safety/","@type":"MedicalWebPage","name":"Help manage your patients’ adverse reactions (ARs) to KEYTRUDA® (pembrolizumab)","description":"When administering KEYTRUDA in combination with LENVIMA, modify the dosage of one or both drugs as appropriate."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/safety/","@type":"MedicalWebPage","name":"Help manage your patients’ adverse reactions to LENVIMA® (lenvatinib)","description":"Withhold, reduce, or discontinue LENVIMA based on the type and/or severity (grade) of the adverse reaction."}`
    ]
}

const jobCode = jobCodes[0].jobCode;

const LenvimaSubsectionIntro = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const KeytrudaSection = styled(Section)`
    padding-bottom: 30px;
    ${respondTo.md`
        padding-bottom: 40px;
    `}
`;

const KeytrudaAccordion = styled(Subsection)`
    gap: 20px;
    ${respondTo.md`
        padding-top: 40px;
    `}
`;

const AccordionTitle = styled(H4)`
    margin-bottom: 10px;
`;

const FootnotesSection = styled(Section)`
    padding-bottom: 30px;
    ${respondTo.md`
        padding-bottom: 40px;
    `}
`;

const FootnotesSubsection = styled(Subsection)`
    word-wrap: break-word;
    ${respondTo.md`
        padding-top: 40px;
    `}
`;

const CtcaeReference = styled(Reference)`
    div {
        display: flex;
        p {
            display: block;
        }
    }
`;

const IconNoBullets = styled(TextIcon1Column)`
    align-items: flex-start;


    ${respondTo.md`
        align-items: center;
    `}

`;

const RCLinks = [
    {
        eyebrow: 'Dosing',
        title: 'Learn more about dosing and administration',
        to: '/dosing'
    },
    {
        eyebrow: 'Resources',
        title: 'Learn more about resources for health care professionals',
        to: '/professional-resources'
    },
];

const AnchorNavLinks = [
    {
        title: 'KEYTRUDA',
        href:'#keytruda',
        style: 'keytruda'
    },
    {
        title: 'LENVIMA',
        href:'#lenvima',
        style: 'lenvima'
    },
    {
        title: 'Definitions and Terms',
        href:'#definitions-and-terms',
    }
];

const Safety = ({location}) => {
    return (
        <DefaultLayout location={ location } name='Adverse Reaction Management' anchorNavLinks = {AnchorNavLinks} pageMeta={pageMeta} jobCode={jobCode}>
            <KeytrudaSection title='KEYTRUDA' id='keytruda' type='keytruda'>
                <H3>Help manage your patients’ adverse reactions (ARs) to <b><keytitle>KEYTRUDA<sup>®</sup><DesktopOnly>&nbsp;</DesktopOnly><MobileOnly> </MobileOnly>(pembrolizumab)</keytitle></b></H3>
                <Subsection>
                    <TextIcon1Column type='keytruda' titleType='default' noTitle>
                        <Ul>
                            <li>When administering KEYTRUDA in combination with LENVIMA, modify the dosage of one or both drugs as appropriate. Withhold or discontinue KEYTRUDA as shown below.</li>
                            <li>No dose reductions of KEYTRUDA are recommended.</li>
                            <li>In general, withhold KEYTRUDA for severe (Grade 3) immune-mediated adverse reactions.</li>
                            <li>Permanently discontinue KEYTRUDA for:</li>
                            <ul>
                                <li>Life-threatening (Grade 4) immune-mediated adverse reactions.</li>
                                <li>Recurrent severe (Grade 3) immune-mediated reactions that require systemic immunosuppressive treatment.</li>
                                <li>An inability to reduce corticosteroid dose to 10 mg or less of prednisone or equivalent per day within 12 weeks of initiating steroids.</li>
                            </ul>
                            <li>Dosage modifications for KEYTRUDA for adverse reactions that require management that differs from these general guidelines are summarized below.</li>
                        </Ul>
                    </TextIcon1Column>
                    <Body><b>Immune-mediated adverse reactions, which may be severe or fatal, can occur in any organ system or tissue, can affect more than one body system simultaneously, and can occur at any time after starting treatment or after discontinuation of treatment. Important immune&#8209;mediated adverse reactions listed here may not include all possible severe and fatal immune<DesktopOnly>&#8209;</DesktopOnly><MobileOnly>-</MobileOnly>mediated adverse reactions.</b></Body>
                    <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' titleType='default' imgAlt=''>
                        <Body><b>Monitor patients closely for symptoms and signs that may be clinical manifestations of underlying immune-mediated adverse reactions.</b></Body>
                        <Ul>
                            <li>Early identification and management are essential to ensure safe use of anti–PD&#8209;1/PD&#8209;L1 treatments.</li>
                            <li>Evaluate liver enzymes, creatinine, and thyroid function at baseline and periodically during treatment.</li>
                            <li>In cases of suspected immune-mediated adverse reactions, initiate appropriate workup to exclude alternative etiologies, including infection.</li>
                            <li>Institute medical management promptly, including specialty consultation as appropriate.</li>
                        </Ul>
                    </TextIcon1Column>
                    <TextIcon1Column imgSrc={ivIconGreen} type='keytruda' titleType='default' imgAlt=''>
                        <Body><b>Withhold or permanently discontinue KEYTRUDA depending on severity of the immune-mediated adverse reaction.</b></Body>
                        <Ul>
                            <li>In general, if KEYTRUDA requires interruption or discontinuation, administer systemic corticosteroid therapy (1 to 2 mg/kg/day prednisone or equivalent) until improvement to Grade 1 or less.</li>
                            <li>Upon improvement to Grade 1 or less, initiate corticosteroid taper and continue to taper over at least 1 month.</li>
                            <li>Consider administration of other systemic immunosuppressants in patients whose immune-mediated adverse reactions are not controlled with corticosteroid therapy.</li>
                            <li>Toxicity management guidelines for adverse reactions that do not necessarily require systemic steroids (eg, endocrinopathies and dermatologic reactions) are shown below.</li>
                            <li>Additional monitoring and management considerations for selected immune-mediated adverse reactions are also shown.</li>
                        </Ul>
                    </TextIcon1Column>
                    <TextIcon1Column imgSrc={personIcon} type='keytruda' titleType='default' imgAlt=''>
                        <Body><b>Patient Counseling Information</b></Body>
                        <Ul>
                            <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                        </Ul>
                    </TextIcon1Column>
                    <Reference>PD&#8209;1 = programmed death receptor-1; PD&#8209;L1 = programmed death ligand 1.</Reference>
                </Subsection>
                <KeytrudaAccordion>
                    <AccordionTitle>Recommended adverse reaction monitoring and management for <keytitle><b>KEYTRUDA</b></keytitle></AccordionTitle>
                    <Accordion title='Immune-Mediated Pneumonitis' type='keytruda'>
                        <IconNoBullets imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                                <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='keytruda'>
                            <Body>Grade 2</Body>
                            <Body>Withhold KEYTRUDA<sup>a</sup></Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Grade 3 or 4</Body>
                            <Body>Permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <Reference><p><sup>a</sup>Resume in patients with complete or partial resolution (Grade 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10 mg per day or less (or equivalent) within 12 weeks of initiating steroids.</p></Reference>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                                <H4><b>Patient Counseling Information</b></H4>
                                <Ul>
                                    <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                    <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                    <li>Please advise patients to contact their health care provider immediately for new or worsening cough, chest pain, or shortness of breath.</li>
                                </Ul>
                            </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Immune-Mediated Colitis' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Colitis may present with diarrhea.</li>
                                <li>CMV infection/reactivation has been reported in patients with corticosteroid-refractory immune-mediated colitis. In cases of corticosteroid-refractory colitis, consider repeating infectious workup to exclude alternative etiologies.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='keytruda'>
                            <Body>Grade 2 or 3</Body>
                            <Body>Withhold KEYTRUDA<sup>a</sup></Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Grade 4</Body>
                            <Body>Permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <Reference><p><sup>a</sup>Resume in patients with complete or partial resolution (Grade 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10 mg per day or less (or equivalent) within 12 weeks of initiating steroids.</p></Reference>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Please advise patients to contact their health care provider immediately for diarrhea or severe abdominal pain.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Reference>
                            CMV = cytomegalovirus.
                        </Reference>
                    </Accordion>
                    <Accordion title='Immune-Mediated Hepatitis With No Tumor Involvement of the Liver' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Evaluate liver enzymes at baseline and periodically during treatment.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                                <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='keytruda'>
                            <Body>AST or ALT increases to more than 3 and up to 8 times ULN</Body>
                            <Body>Withhold KEYTRUDA<sup>a</sup></Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Total bilirubin increases to more than 1.5 and up to 3 times ULN</Body>
                            <Body>Withhold KEYTRUDA<sup>a</sup></Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>AST or ALT increases to more than 8 times ULN</Body>
                            <Body>Permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Total bilirubin increases to more than 3 times ULN</Body>
                            <Body>Permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <Reference>
                            <p><sup>a</sup>Resume in patients with complete or partial resolution (Grade 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10 mg per day or less (or equivalent) within 12 weeks of initiating steroids.</p>
                        </Reference>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Please advise patients to contact their health care provider immediately for jaundice, severe nausea or vomiting, or easy bruising or bleeding.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Reference>
                            AST = aspartate aminotransferase; ALT = alanine aminotransferase; ULN = upper limit of normal.
                        </Reference>
                    </Accordion>
                    <Accordion title='Immune-Mediated Hepatitis With Tumor Involvement of the Liver' type='keytruda'>
                    <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Evaluate liver enzymes at baseline and periodically during treatment.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                                <H4><b>Management<sup>a</sup></b></H4>
                        </IconNoBullets>
                        <Table type='keytruda'>
                            <Body>Baseline AST or ALT is more than 1 and up to 3 times ULN and increases to more than 5 and up to 10 times ULN</Body>
                            <Body>Withhold KEYTRUDA<sup>b</sup></Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Baseline AST or ALT is more than 3 times and up to 5 times ULN and increases to more than 8 times and up to 10 times ULN</Body>
                            <Body>Withhold KEYTRUDA<sup>b</sup></Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Baseline AST or ALT increases to more than 10 times ULN</Body>
                            <Body>Permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Total bilirubin increases to more than 3 times ULN</Body>
                            <Body>Permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <Reference>
                            <p><sup>a</sup>If AST and ALT are less than or equal to ULN at baseline, withhold or permanently discontinue KEYTRUDA based on recommendations for hepatitis with no liver involvement.</p>
                            <p><sup>b</sup>Resume in patients with complete or partial resolution (Grade 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10 mg per day or less (or equivalent) within 12 weeks of initiating steroids.</p>
                        </Reference>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Please advise patients to contact their health care provider immediately for jaundice, severe nausea or vomiting, or easy bruising or bleeding.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Reference>
                            AST = aspartate aminotransferase; ALT = alanine aminotransferase; ULN = upper limit of normal.
                        </Reference>
                    </Accordion>
                    <Accordion title='Adrenal Insufficiency (Immune-Mediated Endocrinopathies)' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>KEYTRUDA can cause primary or secondary adrenal insufficiency.</li>
                                <li>Systemic corticosteroids were required in 77% (17/22) of patients with adrenal insufficiency; of these, the majority remained on systemic corticosteroids.</li>
                                <li>Monitor patients for signs and symptoms of adrenal insufficiency.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                                <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='keytruda'>
                            <Body>Grade 2 or higher</Body>
                            <Body>Initiate symptomatic treatment, including hormone replacement as clinically indicated. Withhold KEYTRUDA depending on severity</Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Grade 3 or 4</Body>
                            <Body>Initiate symptomatic treatment, including hormone replacement as clinically indicated. Withhold until clinically stable or permanently discontinue KEYTRUDA depending on severity</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Advise patients to contact their health care provider immediately for signs or symptoms of adrenal insufficiency.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Hypophysitis (Immune-Mediated Endocrinopathies)' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Hypophysitis can present with acute symptoms associated with mass effect such as headache, photophobia, or visual field defects.</li>
                                <li>Hypophysitis can cause hypopituitarism.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Management</b></H4>
                            <Ul>
                                <li>Initiate hormone replacement as indicated.</li>
                                <ul>
                                    <li>Systemic corticosteroids were required in 94% (16/17) of patients with hypophysitis; of these, the majority remained on systemic corticosteroids.</li>
                                </ul>
                            </Ul>
                        </TextIcon1Column>
                        <Table type='keytruda'>
                            <Body>Grade 3 or 4</Body>
                            <Body>Withhold until clinically stable or permanently discontinue KEYTRUDA depending on severity</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Advise patients to contact their health care provider immediately for signs or symptoms of hypophysitis.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Thyroid Disorders (Immune-Mediated Endocrinopathies)' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Thyroid disorders can include thyroiditis, hyperthyroidism, and hypothyroidism.</li>
                                <li>Thyroiditis can present with or without endocrinopathy.</li>
                                <li>Hypothyroidism can follow hyperthyroidism.</li>
                                <li>Evaluate thyroid function at baseline and periodically during treatment.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Management</b></H4>
                            <Ul>
                                <li>Initiate treatment (hormone replacement for hypothyroidism or institute medical management for hyperthyroidism) as clinically indicated.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Table type='keytruda'>
                            <Body>Grade 3 or 4</Body>
                            <Body>Withhold until clinically stable or permanently discontinue KEYTRUDA depending on severity</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Advise patients to contact their health care provider immediately for signs or symptoms of hypothyroidism or hyperthyroidism.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Type 1 Diabetes Mellitus, Which Can Present With Diabetic Ketoacidosis (Immune-Mediated Endocrinopathies)' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Type 1 diabetes mellitus can present with diabetic ketoacidosis.</li>
                                <li>Monitor patients for hyperglycemia or other signs and symptoms of diabetes.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Management</b></H4>
                            <Ul>
                                <li>Initiate treatment with insulin as clinically indicated.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Table type='keytruda'>
                            <Body>Grade 3 or 4</Body>
                            <Body>Withhold until clinically stable or permanently discontinue KEYTRUDA depending on severity</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Advise patients to contact their health care provider immediately for signs or symptoms of type 1 diabetes.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Immune-Mediated Nephritis With Renal Dysfunction' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>KEYTRUDA can cause immune-mediated nephritis.</li>
                                <li>Evaluate creatinine at baseline and periodically during treatment.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='keytruda'>
                            <Body>Grade 2 or 3 increased blood creatinine</Body>
                            <Body>Withhold KEYTRUDA<sup>a</sup></Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Grade 4 increased blood creatinine</Body>
                            <Body>Permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <Reference>
                            <p><sup>a</sup>Resume in patients with complete or partial resolution (Grade 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10 mg per day or less (or equivalent) within 12 weeks of initiating steroids.</p>
                        </Reference>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Advise patients to contact their health care provider immediately for signs or symptoms of nephritis.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Immune-Mediated Dermatologic Adverse Reactions' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>KEYTRUDA can cause immune-mediated rash or dermatitis.</li>
                                <li>Exfoliative dermatitis, including SJS, DRESS, and TEN, has occurred with PD&#8209;1/PD&#8209;L1 blocking antibodies. Monitor patients for signs and symptoms of suspected severe skin reactions.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Management</b></H4>
                            <Ul>
                                <li>Topical emollients and/or topical corticosteroids may be adequate to treat mild to moderate nonexfoliative rashes.</li>
                                <li>Withhold or permanently discontinue KEYTRUDA depending on severity.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Table type='keytruda'>
                            <Body>Exfoliative Dermatologic Conditions: Suspected SJS, TEN, or DRESS</Body>
                            <Body>Withhold KEYTRUDA<sup>a</sup></Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Exfoliative Dermatologic Conditions: Confirmed SJS, TEN, or DRESS</Body>
                            <Body>Permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <Reference>
                            <p><sup>a</sup>Resume in patients with complete or partial resolution (Grade 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10 mg per day or less (or equivalent) within 12 weeks of initiating steroids.</p>
                        </Reference>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Advise patients to contact their health care provider immediately for any signs or symptoms of severe skin reactions, SJS, or TEN.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Reference>
                            SJS = Stevens-Johnson syndrome; DRESS = drug rash with eosinophilia and systemic symptoms; TEN = toxic epidermal necrolysis; PD&#8209;1 = programmed death receptor-1; PD&#8209;L1 = programmed death ligand 1.
                        </Reference>
                    </Accordion>
                    <Accordion title='Immune-Mediated Myocarditis' type='keytruda'>
                        <IconNoBullets imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='keytruda'>
                            <Body>Grade 2, 3, or 4</Body>
                            <Body>Permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Advise patients that immune-mediated adverse reactions can occur and may involve any organ system, and to contact their health care provider immediately for any new or worsening signs or symptoms.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Immune-Mediated Neurological Toxicities' type='keytruda'>
                    <IconNoBullets imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Management</b></H4>
                    </IconNoBullets>
                        <Table type='keytruda'>
                            <Body>Grade 2</Body>
                            <Body>Withhold KEYTRUDA<sup>a</sup></Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Grade 3 or 4</Body>
                            <Body>Permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <Reference>
                            <p><sup>a</sup>Resume in patients with complete or partial resolution (Grade 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10 mg per day or less (or equivalent) within 12 weeks of initiating steroids.</p>
                        </Reference>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Advise patients that immune-mediated adverse reactions can occur and may involve any organ system, and to contact their health care provider immediately for any new or worsening signs or symptoms.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Complications of Allogeneic Hematopoietic Stem Cell Transplantation (HSCT)' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Fatal and other serious complications can occur in patients who receive allogeneic HSCT before or after being treated with a PD&#8209;1/PD&#8209;L1 blocking antibody.</li>
                                <li>Transplant-related complications include hyperacute graft-versus-host disease (GVHD), acute GVHD, chronic GVHD, hepatic veno-occlusive disease (VOD) after reduced intensity conditioning, and steroid-requiring febrile syndrome (without an identified infectious cause).</li>
                                <li>These complications may occur despite intervening therapy between PD&#8209;1/PD&#8209;L1 blockade and allogeneic HSCT.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Management</b></H4>
                            <Ul>
                                <li>Follow patients closely for evidence of transplant-related complications and intervene promptly.</li>
                                <li>Consider the benefit vs risks of treatment with a PD&#8209;1/PD&#8209;L1 blockade antibody prior to or after an allogeneic HSCT.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Advise patients of the risk of post-allogeneic hematopoietic stem cell transplantation complications.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Reference>
                            PD&#8209;1 = programmed death receptor-1; PD&#8209;L1 = programmed death ligand 1.
                        </Reference>
                    </Accordion>
                    <Accordion title='Increased Mortality in Patients With Multiple Myeloma When KEYTRUDA Is Added to a Thalidomide Analogue and Dexamethasone' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>In 2 randomized trials in patients with multiple myeloma, the addition of KEYTRUDA to a thalidomide analogue plus dexamethasone, a use for which no PD&#8209;1 or PD&#8209;L1 blocking antibody is indicated, resulted in increased mortality. Treatment of patients with multiple myeloma with a PD&#8209;1 or PD&#8209;L1 blocking antibody in combination with a thalidomide analogue plus dexamethasone is not recommended outside of controlled trials.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                            </Ul>
                        </TextIcon1Column>
                        <Reference>
                            PD&#8209;1 = programmed death receptor-1; PD&#8209;L1 = programmed death ligand 1.
                        </Reference>
                    </Accordion>
                    <Accordion title='Other Immune-Mediated Adverse Reactions (IMARs)' type='keytruda'>
                        <TextIcon1Column type='keytruda' noTitle>
                            <Ul>
                                <li>The following clinically significant IMARs occurred in patients who received KEYTRUDA or were reported with the use of other PD&#8209;1/PD&#8209;L1 blocking antibodies. Severe or fatal cases have been reported for some of these adverse reactions.</li>
                                <ul>
                                    <li><i>Cardiac/Vascular:</i> Myocarditis, pericarditis, vasculitis.</li>
                                    <li><i>Nervous System:</i> Meningitis, encephalitis, myelitis and demyelination, myasthenic syndrome/myasthenia gravis (including exacerbation), Guillain-Barré syndrome, nerve paresis, autoimmune neuropathy.</li>
                                    <li><i>Ocular:</i> Uveitis, iritis and other ocular inflammatory toxicities can occur. Some cases can be associated with retinal detachment. Various grades of visual impairment, including blindness, can occur. If uveitis occurs in combination with other immune-mediated adverse reactions, consider a Vogt-Koyanagi-Harada-like syndrome, as this may require treatment with systemic steroids to reduce the risk of permanent vision loss.</li>
                                    <li><i>Gastrointestinal:</i> Pancreatitis, to include increases in serum amylase and lipase levels, gastritis, duodenitis.</li>
                                    <li><i>Musculoskeletal and Connective Tissue:</i> Myositis/polymyositis, rhabdomyolysis (and associated sequelae, including renal failure), arthritis, polymyalgia rheumatica.</li>
                                    <li><i>Endocrine:</i> Hypoparathyroidism.</li>
                                    <li><i>Hematologic/Immune:</i> Hemolytic anemia, aplastic anemia, hemophagocytic lymphohistiocytosis, systemic inflammatory response syndrome, histiocytic necrotizing lymphadenitis (Kikuchi lymphadenitis), sarcoidosis, immune thrombocytopenic purpura, solid organ transplant rejection, other transplant (including corneal graft) rejection.</li>
                                </ul>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Inform patients of the risk of immune-mediated adverse reactions that may be severe or fatal, may occur after discontinuation of treatment, and may require corticosteroid treatment and interruption or discontinuation of KEYTRUDA.</li>
                                <li>Advise patients that immune-mediated adverse reactions can occur and may involve any organ system, and to contact their health care provider immediately for any new or worsening signs or symptoms.</li>
                                <li>Advise patients of the risk of solid organ transplant rejection and to contact their health care provider immediately for signs or symptoms of organ transplant rejection.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Reference>
                            PD&#8209;1 = programmed death receptor-1; PD&#8209;L1 = programmed death ligand 1.
                        </Reference>
                    </Accordion>
                    <Accordion title='Infusion-Related Reactions' type='keytruda'>
                        <TextIcon1Column imgSrc={clipboardIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>KEYTRUDA can cause severe or life-threatening infusion-related reactions, including hypersensitivity and anaphylaxis.</li>
                                <li>Monitor patients for signs and symptoms of infusion-related reactions including rigors, chills, wheezing, pruritus, flushing, rash, hypotension, hypoxemia, and fever.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={ivIconGreen} type='keytruda' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='keytruda'>
                            <Body>Grade 1 or 2</Body>
                            <Body>Interrupt or slow the rate of KEYTRUDA infusion</Body>
                        </Table>
                        <Table type='keytruda'>
                            <Body>Grade 3 or 4</Body>
                            <Body>Stop infusion and permanently discontinue KEYTRUDA</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Advise patients to contact their health care provider immediately for signs or symptoms of infusion-related reactions.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Embryo-Fetal Toxicity' type='keytruda'>
                        <TextIcon1Column type='keytruda'>
                            <H4><b>Considerations</b></H4>
                            <Ul>
                                <li>Based on its mechanism of action, KEYTRUDA can cause fetal harm when administered to a pregnant woman.</li>
                                <li>Advise women of the potential risk to a fetus.</li>
                                <li>Verify pregnancy status in females of reproductive potential prior to initiating KEYTRUDA.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Advise females of reproductive potential of the potential risk to a fetus and to inform their health care provider of a known or suspected pregnancy.</li>
                                <li>Advise females of reproductive potential to use effective contraception during treatment with KEYTRUDA and for 4 months after the last dose.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Lactation (Use in Specific Populations)' type='keytruda' warningMsg={false}>
                        <TextIcon1Column type='keytruda'>
                            <H4><b>Considerations</b></H4>
                            <Ul>
                                <li>There are no data on the presence of KEYTRUDA in either animal or human milk or its effects on the breastfed child or on milk production.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={personIcon} type='keytruda' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Medication Guide).</li>
                                <li>Because of the potential for serious adverse reactions in breastfed children, advise women not to breastfeed during treatment with KEYTRUDA and for 4 months after the last dose.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                </KeytrudaAccordion>
            </KeytrudaSection>
            <Section title='LENVIMA' type='lenvima' id='lenvima' bgColor='cloud'>
                <H3>Help manage your patients’ adverse reactions to <b><lentitle>LENVIMA<sup>®</sup>&nbsp;(lenvatinib)</lentitle></b></H3>
                <Subsection>
                    <LenvimaSubsectionIntro>
                        <Ul marker={colors.mulberry}>
                            <li>Recommendations for adverse reaction management, including dose modifications, are included in the Prescribing Information for LENVIMA and outlined below.</li>
                            <li>When administering LENVIMA in combination with KEYTRUDA, modify the dosage of one or both drugs as appropriate. Withhold, dose reduce, or discontinue LENVIMA as shown below.</li>
                        </Ul>
                        <Body><b>Withhold, reduce, or discontinue LENVIMA based on the type and/or severity (grade) of the adverse reaction.</b></Body>
                    </LenvimaSubsectionIntro>
                    <Chart type='lenvima' imgSrc={chartMobileImg} desktopImgSrc={chartDesktopImg} imgAlt='Recommended Dosage Reductions for LENVIMA® (lenvatinib)'>
                        <p>Recommended dosage reductions for LENVIMA for patients with advanced renal cell carcinoma or advanced endometrial carcinoma<sup>a</sup></p>
                        <Reference>
                            <p><sup>a</sup>When administered with KEYTRUDA.<br/><br/>Capsules are not shown at actual size.</p>
                        </Reference>
                    </Chart>
                    <Ul marker={colors.mulberry}>
                        <li>The recommended dosage of LENVIMA for patients with <b>advanced renal cell carcinoma or advanced endometrial carcinoma and severe renal impairment</b> (creatinine clearance less than 30 mL/min calculated by Cockcroft-Gault equation using actual body weight) is <b>10 mg orally once daily.</b></li>
                        <li>The recommended dosage of LENVIMA for patients with <b>advanced renal cell carcinoma or advanced endometrial carcinoma and severe hepatic impairment</b> (Child-Pugh C) is <b>10 mg orally once daily.</b></li>
                    </Ul>
                </Subsection>
                <KeytrudaAccordion>
                    <AccordionTitle>Recommended adverse reaction monitoring and management for <lentitle><b>LENVIMA</b></lentitle></AccordionTitle>
                    <Accordion title='Hypertension' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Control blood pressure (BP) prior to initiation of LENVIMA.</li>
                                <li>Monitor BP after 1 week, then every 2 weeks for the first 2 months and at least monthly thereafter during treatment.</li>
                                <li>Serious complications of poorly controlled hypertension have been reported.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>Grade 3</Body>
                            <Body>Withhold LENVIMA for Grade 3 that persists despite optimal antihypertensive therapy</Body>
                            <Body>Resume LENVIMA at a reduced dose when hypertension is controlled at ≤ Grade 2</Body>
                        </Table>
                        <Table type='lenvima'>
                            <Body>Grade 4</Body>
                            <Body>Permanently discontinue LENVIMA</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients to undergo regular BP monitoring and to contact their health care provider if BP is elevated.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Cardiac Dysfunction' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Serious and fatal cardiac dysfunction can occur with LENVIMA.</li>
                                <li>Monitor patients for clinical symptoms or signs of cardiac dysfunction.</li>
                                <li>Cardiomyopathy, left or right ventricular dysfunction, congestive heart failure, cardiac failure, ventricular hypokinesia, or decrease in left or right ventricular ejection fraction of >20% from baseline have been reported with LENVIMA.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>Grade 3</Body>
                            <Body>Withhold LENVIMA until improves to Grade 0 to 1 or baseline</Body>
                            <Body>Resume at a reduced dose or discontinue LENVIMA depending on the severity and persistence of adverse reaction</Body>
                        </Table>
                        <Table type='lenvima'>
                            <Body>Grade 4</Body>
                            <Body>Permanently discontinue LENVIMA</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients that LENVIMA can cause cardiac dysfunction and to immediately contact their health care provider if they experience any clinical symptoms of cardiac dysfunction.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Arterial Thromboembolic Events' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>The safety of resuming LENVIMA after an arterial thromboembolic event has not been established and LENVIMA has not been studied in patients who have had an arterial thromboembolic event within the previous 6 months.</li>
                                <ul>
                                    <li>Grade 3 to 5 arterial thromboembolic events ranged from 2% to 3% across all clinical trials with LENVIMA.</li>
                                </ul>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>Any grade of arterial thromboembolic event</Body>
                            <Body>Permanently discontinue LENVIMA</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients to seek immediate medical attention for new onset chest pain or acute neurologic symptoms consistent with myocardial infarction or stroke.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Hepatotoxicity' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Monitor liver function prior to initiating LENVIMA.</li>
                                <li>Monitor liver function every 2 weeks for the first 2 months and at least monthly thereafter during treatment.</li>
                                <li>Serious hepatic adverse reactions and fatal events, including hepatic failure, acute hepatitis, and hepatorenal syndrome, have occurred in patients treated with LENVIMA.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>Grade 3 or 4</Body>
                            <Body>Withhold LENVIMA until improves to Grade 0 to 1 or baseline</Body>
                            <Body>Either resume at a reduced dose or discontinue LENVIMA depending on severity and persistence of hepatotoxicity</Body>
                            <Body>Permanently discontinue LENVIMA for hepatic failure</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients that they will need to undergo laboratory tests to monitor liver function and to report any new symptoms indicating hepatic toxicity or failure.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Renal Failure or Impairment' type='lenvima' imgAlt=''>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima'>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Serious including fatal renal failure or impairment can occur with LENVIMA.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                            <Ul>
                                <li>Initiate prompt management of diarrhea or dehydration/hypovolemia.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Table type='lenvima'>
                            <Body>Grade 3 or 4</Body>
                            <Body>Withhold LENVIMA until improves to Grade 0 to 1 or baseline</Body>
                            <Body>Resume at a reduced dose or discontinue LENVIMA depending on severity and persistence of renal impairment</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients that they will need to undergo regular laboratory tests to monitor kidney function.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Proteinuria' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Monitor for proteinuria prior to initiating LENVIMA and periodically during treatment.</li>
                                <ul>
                                    <li>If proteinuria ≥ 2+ is detected on urine dipstick, obtain a 24-hour urine protein sample.</li>
                                </ul>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>≥ 2 g proteinuria in 24 hours</Body>
                            <Body>Withhold LENVIMA until ≤ 2 g of proteinuria per 24 hours</Body>
                            <Body>Resume LENVIMA at a reduced dose</Body>
                            <Body>Permanently discontinue LENVIMA for nephrotic syndrome</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients that they will need to undergo regular laboratory tests to monitor protein in urine.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Diarrhea' type='lenvima'>
                        <TextIcon1Column imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                            <Ul>
                                <li>Promptly initiate management of diarrhea.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Table type='lenvima'>
                            <Body>Grade 2 or 3; Persistent or intolerable adverse reaction:</Body>
                            <Body>Withhold LENVIMA until improves to Grade 0 to 1 or baseline</Body>
                            <Body>Resume LENVIMA at a reduced dose</Body>
                        </Table>
                        <Table type='lenvima'>
                            <Body>Grade 4</Body>
                            <Body>Permanently discontinue LENVIMA</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients when to start standard anti-diarrheal therapy and to maintain adequate hydration.</li>
                                <li>Advise patients to contact their health care provider if they are unable to maintain adequate hydration.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Gastrointestinal Perforation' type='lenvima'>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>Any grade of gastrointestinal perforation</Body>
                            <Body>Permanently discontinue LENVIMA</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients that LENVIMA can increase the risk of gastrointestinal perforation and to seek immediate medical attention for severe abdominal pain.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Fistula Formation' type='lenvima'>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>Grade 3 or 4</Body>
                            <Body>Permanently discontinue LENVIMA</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients that LENVIMA can increase the risk of fistula formation and to seek immediate medical attention for severe abdominal pain.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='QT Interval Prolongation' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>LENVIMA has been reported to prolong the QT/QTc interval.</li>
                                <li>Monitor and correct electrolyte abnormalities at baseline and periodically during treatment.</li>
                                <li>Monitor electrocardiograms in patients with congenital long QT syndrome, congestive heart failure, or bradyarrhythmias, or those who are taking drugs known to prolong QT interval, including Class Ia and III antiarrhythmics.</li>
                                <li>Avoid coadministration of LENVIMA with medicinal products with a known potential to prolong the QT/QTc interval.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>For QT interval > 500 ms or for > 60 ms increase in baseline QT interval</Body>
                            <Body>Withhold LENVIMA until improves to ≤ 480 ms or baseline</Body>
                            <Body>Resume LENVIMA at a reduced dose</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients who are at risk for QTc prolongation that they will need to undergo regular ECGs.</li>
                                <li>Advise all patients that they will need to undergo laboratory tests to monitor electrolytes.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Reference>
                            QTc = corrected QT interval; ECG = electrocardiogram; ms = microsecond.
                        </Reference>
                    </Accordion>
                    <Accordion title='Hypocalcemia' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Monitor blood calcium levels at least monthly.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                            <Ul>
                                <li>Replace calcium as necessary during treatment.</li>
                            </Ul>
                        </TextIcon1Column>
                        <Table type='lenvima'>
                            <Body>Grade 2 or 3; Persistent or intolerable adverse reaction:</Body>
                            <Body>Withhold LENVIMA until improves to Grade 0 to 1 or baseline</Body>
                            <Body>Resume LENVIMA at a reduced dose</Body>
                        </Table>
                        <Table type='lenvima'>
                            <Body>Grade 4</Body>
                            <Body>Withhold LENVIMA until improves to Grade 0 to 1 or baseline</Body>
                            <Body>Resume LENVIMA at a reduced dose</Body>
                            <Body>Permanently discontinue LENVIMA depending on severity</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients of the risks of hypocalcemia, that they will need to undergo laboratory tests to monitor calcium levels, and the potential requirement for calcium supplementation.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Reversible Posterior Leukoencephalopathy Syndrome (RPLS)' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Confirm the diagnosis of RPLS with magnetic resonance imaging.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>Any grade of reversible posterior leukoencephalopathy syndrome</Body>
                            <Body>Withhold and resume at a reduced dose upon recovery or permanently discontinue LENVIMA depending on severity and persistence of neurologic symptoms</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients of the signs and symptoms of RPLS and to contact their health care provider for new onset or worsening neurological function.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Hemorrhagic Events' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Serious including fatal hemorrhagic events can occur with LENVIMA.</li>
                                <li>Serious tumor-related bleeds, including fatal hemorrhagic events, occurred in patients treated with LENVIMA in clinical trials and in the post-marketing setting.</li>
                                <li>In post-marketing surveillance, serious and fatal carotid artery hemorrhages were seen more frequently in patients with anaplastic thyroid carcinoma (ATC) than in other tumor types. The safety and effectiveness of LENVIMA in patients with ATC have not been demonstrated in clinical trials.</li>
                                <li>Consider the risk of severe or fatal hemorrhage associated with tumor invasion or infiltration of major blood vessels (eg, carotid artery).</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>Grade 2 or 3; Persistent or intolerable adverse reaction:</Body>
                            <Body>Withhold LENVIMA until improves to Grade 0 to 1 or baseline</Body>
                            <Body>Resume LENVIMA at a reduced dose</Body>
                        </Table>
                        <Table type='lenvima'>
                            <Body>Grade 4</Body>
                            <Body>Permanently discontinue LENVIMA</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients that LENVIMA can increase the risk for bleeding and to contact their health care provider for bleeding or symptoms of severe bleeding.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Impairment of Thyroid-Stimulating Hormone Suppression/Thyroid Dysfunction' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>LENVIMA impairs exogenous thyroid suppression.</li>
                                <li>Monitor thyroid function prior to initiating LENVIMA and at least monthly during treatment.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                            <Ul>
                                <li>Treat hypothyroidism according to standard medical practice.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients that LENVIMA can cause hypothyroidism and that their thyroid function should be monitored regularly during treatment.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Impaired Wound Healing' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Impaired wound healing has been reported in patients who received LENVIMA.</li>
                                <li>The safety of resumption of LENVIMA after resolution of wound healing complications has not been established.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='default-cloud'>
                            <Body>Withhold LENVIMA for at least 1 week prior to elective surgery</Body>
                            <Body>Do not administer LENVIMA for at least 2 weeks following major surgery and until adequate wound healing</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients that LENVIMA may impair wound healing.</li>
                                <li>Advise patients to inform their health care provider of any planned surgical procedure.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Osteonecrosis of the Jaw (ONJ)' type='lenvima'>
                        <TextIcon1Column imgSrc={clipboardIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Monitoring Considerations</b></H4>
                            <Ul>
                                <li>Osteonecrosis of the jaw has been reported in patients receiving LENVIMA.</li>
                                <li>Perform an oral examination prior to treatment with LENVIMA and periodically during LENVIMA treatment.</li>
                                <li>Advise patients regarding good oral hygiene practices.</li>
                                <li>Avoid invasive dental procedures, if possible, while on treatment with LENVIMA, particularly in patients at higher risk.</li>
                                <li>For patients requiring invasive dental procedures, discontinuation of bisphosphonate treatment may reduce the risk of ONJ.</li>
                                <li>Concomitant exposure to other risk factors, such as bisphosphonates, denosumab, dental disease or invasive dental procedures, may increase the risk of ONJ.</li>
                            </Ul>
                        </TextIcon1Column>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='default-cloud'>
                            <Body>Withhold LENVIMA for at least 1 week prior to scheduled dental surgery or invasive dental procedures, if possible</Body>
                            <Body>Withhold LENVIMA if ONJ develops and restart based on clinical judgment of adequate resolution</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise patients regarding good oral hygiene practices and to have preventive dentistry performed prior to treatment with LENVIMA and throughout treatment with LENVIMA.</li>
                                <li>Inform patients being treated with LENVIMA, particularly those who are at high risk for ONJ, to avoid invasive dental procedures, if possible, and to inform their health care provider of any planned dental procedures.</li>
                                <li>Advise patients to immediately contact their health care provider for signs or symptoms associated with ONJ.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Other Adverse Reactions' type='lenvima' warningMsg='[See Warnings and Precautions for Diarrhea, Hypocalcemia, and Hemorrhagic Events]'>
                        <IconNoBullets imgSrc={pillIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Management</b></H4>
                        </IconNoBullets>
                        <Table type='lenvima'>
                            <Body>Grade 2 or 3; Persistent or intolerable adverse reaction; or Grade 4 laboratory abnormalities</Body>
                            <Body>Withhold LENVIMA until improves to Grade 0 to 1 or baseline</Body>
                            <Body>Resume LENVIMA at reduced dose</Body>
                        </Table>
                        <Table type='lenvima'>
                            <Body>Grade 4 adverse reaction</Body>
                            <Body>Permanently discontinue LENVIMA</Body>
                        </Table>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Embryo-Fetal Toxicity' type='lenvima'>
                        <TextIcon1Column type='lenvima'>
                            <H4><b>Risk Summary</b></H4>
                            <Ul>
                                <li>Based on its mechanism of action and data from animal reproduction studies, LENVIMA can cause fetal harm when administered to a pregnant woman.</li>
                                <li>In animal reproduction studies, oral administration of lenvatinib during organogenesis at doses below the recommended clinical doses resulted in embryotoxicity, fetotoxicity, and teratogenicity in rats and rabbits.</li>
                                <li>Advise pregnant women of the potential risk to a fetus.</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise females of reproductive potential of the potential risk to a fetus and to inform their health care provider of a known or suspected pregnancy.</li>
                                <li>Advise females of reproductive potential to use effective contraception during treatment with LENVIMA and for 30 days after the last dose.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                    <Accordion title='Lactation (Use in Specific Populations)' type='lenvima' warningMsg={false}>
                        <TextIcon1Column type='lenvima'>
                            <H4><b>Risk Summary</b></H4>
                            <Ul>
                                <li>It is not known whether LENVIMA is present in human milk; however, lenvatinib and its metabolites are excreted in rat milk at concentrations higher than those in maternal plasma (see <LenLink href='https://www.lenvima.com/-/media/Project/EISAI/Lenvima/PDF/prescribing-information.pdf' target='_blank'>LENVIMA PI</LenLink>).</li>
                            </Ul>
                        </TextIcon1Column>
                        <TextIcon1Column imgSrc={personIconMulberry} type='lenvima' imgAlt=''>
                            <H4><b>Patient Counseling Information</b></H4>
                            <Ul>
                                <li>Advise patients to read the FDA-approved patient labeling (Patient Information).</li>
                                <li>Advise women to discontinue breastfeeding during treatment with LENVIMA and for 1 week after the last dose.</li>
                            </Ul>
                        </TextIcon1Column>
                    </Accordion>
                </KeytrudaAccordion>
            </Section>
            <Section title='DEFINITIONS AND TERMS' id='definitions-and-terms'>
                <H3>FDA and CTCAE definitions for an adverse event and adverse reaction</H3>
                <Subsection>
                    <H4>FDA definitions for an adverse event and adverse reaction<sup>1</sup></H4>
                    <Ul marker={colors.charcoal}>
                        <li><b>Adverse event (AE)</b> means any untoward medical occurrence associated with the use of a drug in humans, whether or not considered drug related.</li>
                        <li><b>Adverse reaction (AR)</b> means any adverse event caused by a drug.</li>
                        <ul>
                            <li>Adverse reactions are a subset of all suspected adverse reactions where there is reason to conclude that the drug caused the event.</li>
                        </ul>
                    </Ul>
                </Subsection>
                <Subsection>
                    <H4>CTCAE terms<sup>2</sup></H4>
                    <Ul marker={colors.charcoal}>
                        <li><b>Grades<sup>a</sup></b> refer to the severity of the AE. The CTCAE displays Grades 1 through 5 with unique clinical descriptions of severity for each AE based on this general guideline:</li>
                        <ul>
                            <li><b>Grade 1:</b> Mild; asymptomatic or mild symptoms; clinical or diagnostic observations only; intervention not indicated.</li>
                            <li><b>Grade 2:</b> Moderate; minimal, local or noninvasive intervention indicated; limiting age-appropriate instrumental ADL.<sup>b</sup></li>
                            <li><b>Grade 3:</b> Severe or medically significant but not immediately life-threatening; hospitalization or prolongation of hospitalization indicated; disabling; limiting self-care ADL.<sup>c</sup></li>
                            <li><b>Grade 4:</b> Life-threatening consequences; urgent intervention indicated.</li>
                            <li><b>Grade 5:</b> Death related to AE.</li>
                        </ul>
                    </Ul>
                    <Body><b>Common Terminology Criteria for Adverse Events (CTCAE) grading definitions are listed according to version 4.0, which is the version that is used in the Prescribing Information for KEYTRUDA and for LENVIMA.</b></Body>
                    <CtcaeReference>
                        <p><sup>a</sup>A semicolon indicates “or” within the description of the grade.</p>
                        <div><sup>b</sup><p>Instrumental ADL refer to preparing meals, shopping for groceries or clothes, using the telephone, managing money, etc.<sup>2</sup></p></div>
                        <div><sup>c</sup><p>Self-care ADL refer to bathing, dressing and undressing, feeding self, using the toilet, taking medications, and not bedridden.<sup>2</sup></p></div><br/>
                        ADL = activities of daily living.
                    </CtcaeReference>
                </Subsection>
            </Section>
            <RelatedContent title='Learn more about dosing and resources' links={RCLinks}/>
            <FootnotesSection noIntro>
                <FootnotesSubsection>
                    <Reference>
                        <b>References: 1.</b> IND application reporting: safety reports. US Food and Drug Administration. Content current as of October 19, 2021. Accessed August 23, 2023. <MobileOnly><span style={{wordBreak: 'break-all'}}>https://www.fda.gov/drugs/investigational-new-drug-ind-application/ind-application-reporting-safety-reports</span></MobileOnly><DesktopOnly>https://www.fda.gov/drugs/investigational-new-drug-ind-application/ind-application-reporting-safety-reports</DesktopOnly>  <b>2.</b> National Cancer Institute (NCI). Common Terminology Criteria for Adverse Events (CTCAE). v4.0 Quick Reference 8.5x11. Published May 28, 2009. <MobileOnly><span style={{wordBreak: 'break-all'}}>https://evs.nci.nih.gov/ftp1/CTCAE/CTCAE_4.03/Archive/CTCAE_4.0_2009-05-29_QuickReference_8.5x11.pdf</span></MobileOnly><DesktopOnly>https://evs.nci.nih.gov/ftp1/CTCAE/CTCAE_4.03/Archive/CTCAE_4.0_2009-05-29_QuickReference_8.5x11.pdf</DesktopOnly>
                    </Reference>
                </FootnotesSubsection>
            </FootnotesSection>
        </DefaultLayout>
    )
};

export default Safety;
